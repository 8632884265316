import React from 'react'
import { Image } from 'react-bootstrap';
import {Helmet} from "react-helmet";

function About() {
  return (
    <div>
        <Helmet>
            <script src="assets/js/main.js" type="text/javascript"/>
        </Helmet>
        <div>
            <section className="about-area ptb-100">
                <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                    <div className="about-image">
                        <Image src="assets/img/New Project.jpg" className="shadow" alt="image" />
                    </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                    <div className="about-content">
                        <span className="sub-title">About Us</span>
                        <h6><strong>Are You Looking to expand your business </strong>it's important to have the right team behind you. That's where Pick My Franchise comes in. We offer a wide range of services, from business Consulting, branding, and digital marketing to ongoing support and guidance to help you grow your business. With our help, your business can reach new heights and become a nationwide phenomenon. Ready to take your business to the next level? </h6>
                        <h6>Pick My franchise has 5+ years of experience in the franchise industry and understand what it takes to successfully expand your brand across all states. With our trademarked process of understanding market and strategy building according to demographics and audience, with handpick brands for investors and aspiring entrepreneurs that have the potential for long-term success. We understand that each business has unique needs, so we take the time to create tailored support plans that meet the needs and make sure that the businesses we work with are set up for success.</h6>
                        <h6>Our investors and entrepreneurs have seen tremendous successes, growing their brands across all states and internationally. Whether it's a regional success or a global one, PMF has the resources and knowledge to help you grow your brand and take it to the next level.</h6>
                        <h6>We work with only the best brands and have a proven track record of success. If you're looking to take your brand nationwide, contact us today to learn more about our franchise opportunities. We’ve helped brands like yours expand to new markets and we can do the same for you. Contact us today to learn more about our franchise opportunities</h6>
                    </div>
                    </div>
                </div>
{/*                 <div className="about-inner-area">
                    <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="about-text">
                        <h3>Our Vision</h3>
                        <p>One of the most popular on the web is shopping.</p>
                        <ul className="features-list">
                            <li><i className="bx bx-check" /> People like Xton</li>
                            <li><i className="bx bx-check" /> World's finest Xton</li>
                            <li><i className="bx bx-check" /> The original Xton</li>
                            <li><i className="bx bx-check" /> We trust Xton</li>
                        </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="about-text">
                        <h3>Our Mission</h3>
                        <p>The best of both worlds. Store and web.</p>
                        <ul className="features-list">
                            <li><i className="bx bx-check" /> Always in style!</li>
                            <li><i className="bx bx-check" /> Discover your favorite shopping</li>
                            <li><i className="bx bx-check" /> Find yourself</li>
                            <li><i className="bx bx-check" /> Feel-good shopping</li>
                        </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                        <div className="about-text">
                        <h3>Our Goal</h3>
                        <p>Rediscover a great shopping tradition</p>
                        <ul className="features-list">
                            <li><i className="bx bx-check" /> Get better shopping</li>
                            <li><i className="bx bx-check" /> Love shopping again</li>
                            <li><i className="bx bx-check" /> Online shopping.</li>
                            <li><i className="bx bx-check" /> Shopping for all seasons</li>
                        </ul>
                        </div>
                    </div>
                    </div>
                </div> */}
                </div>
            </section>
{/*             <section className="testimonials-area ptb-100">
                <div className="container">
                <div className="section-title">
                    <span className="sub-title">Testimonials</span>
                    <h2>What Clients Says About Us</h2>
                </div>
                <div className="testimonials-slides owl-carousel owl-theme">
                    <div className="single-testimonials-item">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                        the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                        of type and scrambled it to make a type specimen book.</p>
                    <div className="info">
                        <Image src="assets/img/user1.jpg" className="shadow rounded-circle" alt="image" />
                        <h3>John Smith</h3>
                        <span>Student</span>
                    </div>
                    </div>
                    <div className="single-testimonials-item">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                        the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                        of type and scrambled it to make a type specimen book.</p>
                    <div className="info">
                        <Image src="assets/img/user2.jpg" className="shadow rounded-circle" alt="image" />
                        <h3>Sarah Taylor</h3>
                        <span>Student</span>
                    </div>
                    </div>
                    <div className="single-testimonials-item">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                        the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                        of type and scrambled it to make a type specimen book.</p>
                    <div className="info">
                        <Image src="assets/img/user3.jpg" className="shadow rounded-circle" alt="image" />
                        <h3>David Warner</h3>
                        <span>Student</span>
                    </div>
                    </div>
                    <div className="single-testimonials-item">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                        the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                        of type and scrambled it to make a type specimen book.</p>
                    <div className="info">
                        <Image src="assets/img/user4.jpg" className="shadow rounded-circle" alt="image" />
                        <h3>James Anderson</h3>
                        <span>Student</span>
                    </div>
                    </div>
                </div>
                </div>
            </section> */}
        </div>
    </div>
  )
}

export default About