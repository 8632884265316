import React from 'react'
import axios from 'axios'
import { useEffect, useState } from "react";
import { Image } from 'react-bootstrap';
import ReactPlayer from 'react-player'
import {Helmet} from "react-helmet";
import {Link} from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from "react-router-dom";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

function Home() {

  const handleDragStart = (e) => e.preventDefault();

  const navigate = useNavigate();
  const [inputs, setInputs] = useState([]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({ ...values, [name]: value }));
  }
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await axios.post(`${process.env.REACT_APP_API_BASE }/buy_franchise/`, inputs).then(function (response) {
                console.log(response.data);
                window.alert("Thanks! We will Contact You Soon");
                navigate('/');
            });
        }
        catch (err) {
            console.error(err);
        }
    }

    const [videos, setvideos] = useState([]);
    useEffect(() => {
        getvideos();
      }, []);
    
      const getvideos = async()=>{
          try{
            await axios.get(`${process.env.REACT_APP_API_BASE }/videos/`).then(function(response) {
              setvideos(response.data);
          });
          }
          catch(err){
            console.error(err);
          }
      }

      const video_html_data = videos.slice(0, 3).map((videos,key)=>
        <div key={key} className="col-lg-4 col-md-6">
        <Helmet>
          <script src="assets/js/main.js" type="text/javascript"/>
        </Helmet>
        <div className="single-blog-post">
            <div className="post-image">
            <ReactPlayer url={`${videos.video_link}`} />
            <div className="date">
                <span>{videos.created_at}</span>
            </div>
            </div>
            <div className="post-content">
            <span className="category">{videos.category}</span>
            <h3>{videos.tittle}</h3>
            </div>
        </div>
        </div>
    );

    const [banner_data, setbanner_data] = useState([]);
      useEffect(() => {
          getbanner_data();
        }, []);
      
    const getbanner_data = async()=>{
        try{
          await axios.get(`${process.env.REACT_APP_API_BASE }/banner/`).then(function(response) {
            setbanner_data(response.data);
        });
        }
        catch(err){
          console.error(err);
        }
    }

/*     let banner_html_data = banner_data.map((banner,key)=>
        <div key={key} className="main-banner banner-bg3" style={{backgroundImage:`url(.${banner.image_path.substring(1,1000)})`}}>
        <div className="d-table">
            <div className="d-table-cell">
            <div className="container">
                <div className="main-banner-content">
                <span className="sub-title">{banner.sub_tittle}</span>
                <h1>{banner.main_tittle}</h1>
                <div className="btn-box">
                    <a href="Buy-Franchise" className="default-btn">Buy Franchise</a>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    ); */

    let banner_html_data = banner_data.map((banner,key)=>
    <img key={key} src={`.${banner.image_path.substring(1,1000)}`} onDragStart={handleDragStart} role="presentation" alt=''/>,
     );

    const [trending_franchise, settrending_franchise] = useState([]);
    useEffect(() => {
        gettrending_franchise();
      }, []);

    const [category, setcategory] = useState([]);
  
    useEffect(() => {
        getcategory();
      }, []);
    
    const getcategory = async()=>{
      try{
        await axios.get(`${process.env.REACT_APP_API_BASE }/category/`).then(function(response) {
          setcategory(response.data);
          
      });
      }
      catch(err){
        console.error(err);
      }
    }

    let category_html_data = category.map((category,key)=><Dropdown.Item key={key} onClick={()=>{filterResult(category.id)}}>{category.category}</Dropdown.Item>);

    const gettrending_franchise = async()=>{
      try{
        await axios.get(`${process.env.REACT_APP_API_BASE }/trending_franchise/`).then(function(response) {
          settrending_franchise(response.data);
      });
      }
      catch(err){
        console.error(err);
      }
    }
    let trending_franchise_html_data = trending_franchise.map((trending_franchise,key)=>
        <div key={key} className="col-lg-3 col-md-6 col-sm-6">
        <div className="single-categories-box">
            <Image src={`.${trending_franchise.image_path.substring(1,1000)}`} alt="image" />
            <div className="content text-white">
{/*             <span>{trending_franchise.tittle}</span> */}
            <a href="Buy-Franchise" className="default-btn">Enquire Now</a>
            </div>
        </div>
        </div>
    );

    const [top_franchise, settop_franchise] = useState([]);

    useEffect(() => {
        gettop_franchise();
      }, []);
    
    const gettop_franchise = async()=>{
      try{
        await axios.get(`${process.env.REACT_APP_API_BASE }/top_franchise/`).then(function(response) {
          settop_franchise(response.data);
      });
      }
      catch(err){
        console.error(err);
      }
    }

    useEffect(() => {
      nonfilterResult();
      // eslint-disable-next-line 
    }, [top_franchise]); 

    const [data, setData] = useState(top_franchise);
    const filterResult = (category) => {
    const result = top_franchise.filter((filtered_data)=>{
        return filtered_data.category_id === category
      });
      setData(result);
    }

    const nonfilterResult = () => {
      setData(top_franchise);
    }

    let top_franchise_html_data = data.map((data,key)=>
        <div key={key} className="col-lg-4 col-md-6 col-sm-6">
          <div className="single-products-box" style={{background:'#f4f4f4'}}>
              <div className="products-image" style={{padding:'20px'}}>
              <Link to={`/${data.master_id}`} > 
              <Image src={`.${data.image_path.substring(1,1000)}`} className="main-image" alt="image" />
              <Image src={`.${data.image_path.substring(1,1000)}`} className="hover-image" alt="image" />
              </Link> 
              </div><br></br>
              <div className='top_franchise'>
                <p style={{color:'black'}}>Top Features</p>
                <ul className="features-list">
                  <li><i className="bx bx-badge-check"/>{data.specification_1}</li>
                  <li><i className="bx bx-badge-check"/>{data.specification_2}</li>
                  <li><i className="bx bx-badge-check"/>{data.specification_3}</li>
                  <li><i className="bx bx-badge-check"/>{data.specification_4}</li>
                </ul>
                <div className="products-content">
                <h3><Link to={`/${data.master_id}`} > {data.franchise_name}</Link> </h3><br />
                <Link to={`/${data.master_id}`} style={{top:'-10px',textDecorationLine:'underline',position:'relative'}}>More Details</Link>
                </div>
              </div>
          </div>
        </div>
    );

    const [clients, setclients] = useState([]);
    useEffect(() => {
        getclients();
      }, []);
    
    const getclients = async()=>{
      try{
        await axios.get(`${process.env.REACT_APP_API_BASE }/clients/`).then(function(response) {
          setclients(response.data);
      });
      }
      catch(err){
        console.error(err);
      }
    }
    
    let clients_html_data = clients.map((clients,key)=>
        <div key={key} className="partner-item">
        <a href="home"><Image src={`.${clients.image_path.substring(1,1000)}`} alt="image" /></a>
        </div>
    );
    
  return (
    <div>
        <div>
            <AliceCarousel mouseTracking items={banner_html_data} 
                disableDotsControls={true} 
                disableButtonsControls={true} 
                autoPlay={true} 
                infinite={true}
                animationDuration={1000}
                autoPlayInterval={2000}
            />
            <section className="categories-banner-area pt-100 pb-70">
                <div className="section-title">
                <a href="Buy-Franchise" className="default-btn">Buy Franchise</a>
                <a href="Submit-Franchise" className="optional-btn">Submit Your Franchise</a>
                </div>
            </section>
            <section className="contact-area ptb-100">
                <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-md-12">
                    <div className="contact-info" style={{fontSize:'3 rem'}}>
                        <h1>Fill This Form to <br></br><b>Pick <br>
                        </br>Your Dream Business !</b></h1>
                    </div>
                    </div>
                    <div className="col-lg-7 col-md-12">
                    <form id="contactForm">
                        <div className="row">
                        <div className="col-lg-12 col-md-6">
                            <div className="form-group">
                            <label>Name <span>*</span></label>
                            <input type="text" name="name" id="name" className="form-control" required data-error="Please enter your name" placeholder="Your name" onChange={handleChange}/>
                            <div className="help-block with-errors" />
                            </div>
                            <br></br>
                        </div>
                        <div className="col-lg-12 col-md-6">
                            <div className="form-group">
                            <label>Email <span>*</span></label>
                            <input type="email" name="email" id="email" className="form-control" required data-error="Please enter your email" placeholder="Your email address" onChange={handleChange}/>
                            <div className="help-block with-errors" />
                            </div><br></br>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                            <label>Phone Number <span>*</span></label>
                            <input type="text" name="phone" id="phone" className="form-control" required data-error="Please enter your phone number" placeholder="Your phone number" onChange={handleChange}/>
                            <div className="help-block with-errors" />
                            </div><br></br>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                            <label>Franchise Name<span>*</span></label>
                            <textarea name="franchise_name" id="franchise_name" cols={30} rows={5} required data-error="Please enter franchise name" className="form-control" placeholder="Write your franchise name..." defaultValue={""} onChange={handleChange}/>
                            <div className="help-block with-errors" />
                            </div><br></br>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <button type="submit" className="default-btn" onClick={handleSubmit}>Send</button>
                            <div id="msgSubmit" className="h3 text-center hidden" />
                            <div className="clearfix" />
                        </div>
                        </div>
                    </form>
                    </div>
                </div>
                </div>
            </section>
            <section className="categories-banner-area pt-100 pb-70">
                <div className="section-title">
                <h2>Trending Franchises</h2>
                </div>
                <div className="container-fluid">
                <div className="row">
                    {trending_franchise_html_data}
                </div>
                </div>
            </section>
            <section className="products-area pb-70">
                <div className="container">
                <div>
                  <Dropdown>
                    <Dropdown.Toggle variant="warning" id="dropdown-basic">
                      Category
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={()=>{nonfilterResult()}}>All</Dropdown.Item>
                      {category_html_data}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="section-title">
                    <span className="sub-title">Franchise List</span>
                    <h2>Top Franchises</h2>
                </div>
                <div className="row">
                    {top_franchise_html_data}
                </div>
                </div>
            </section>
            <div className="partner-area ptb-70">
                <div className="container">
                <div className="section-title">
                    <h2>Our Clients</h2>
                </div>
                <div className="partner-slides owl-carousel owl-theme">
                    {clients_html_data}
                </div>
                </div>
            </div>
            <section className="blog-area pt-100 pb-70">
                <div className="container">
                <div className="section-title">
                    <span className="sub-title">Video's</span>
                    <h2>Trending Videos</h2>
                </div>
                <div className="row">
                    {video_html_data}
                </div>
                <div className="section-title">
                    <a href='Videos'><span className="sub-title">More Video's ...</span></a>
                </div>
                </div>
            </section>
        </div>
    </div>
  )
}

export default Home