import React from 'react'
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function BuyFranchise() {

    const navigate = useNavigate();
    const [inputs, setInputs] = useState([]);
  
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }));
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await axios.post(`${process.env.REACT_APP_API_BASE }/buy_franchise/`, inputs).then(function (response) {
                console.log(response.data);
                window.alert("Thanks! We will Contact You Soon");
                navigate('/');
            });
        }
        catch (err) {
            console.error(err);
        }
    }

  return (
    <div>
        <section className="contact-area ptb-100">
            <div className="container">
                <div className="row">
                <div className="col-lg-12 col-md-12">
                    <div className="contact-form">
                    <h3>If You Want Franchise ?</h3>
                    <form id="contactForm">
                        <div className="row">
                        <div className="col-lg-12 col-md-6">
                            <div className="form-group">
                            <label>Name <span>*</span></label>
                            <input type="text" name="name" id="name" className="form-control" required data-error="Please enter your name" placeholder="Your name" onChange={handleChange}/>
                            <div className="help-block with-errors" />
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-6">
                            <div className="form-group">
                            <label>Email <span>*</span></label>
                            <input type="email" name="email" id="email" className="form-control" required data-error="Please enter your email" placeholder="Your email address" onChange={handleChange}/>
                            <div className="help-block with-errors" />
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                            <label>Phone Number <span>*</span></label>
                            <input type="text" name="phone" id="phone" className="form-control" required data-error="Please enter your phone number" placeholder="Your phone number" onChange={handleChange}/>
                            <div className="help-block with-errors" />
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                            <label>Franchise Name<span>*</span></label>
                            <textarea name="franchise_name" id="franchise_name" cols={30} rows={5} required data-error="Please enter franchise name" className="form-control" placeholder="Write your franchise name..." defaultValue={""} onChange={handleChange}/>
                            <div className="help-block with-errors" />
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <button type="submit" className="default-btn" onClick={handleSubmit}>Send</button>
                            <div id="msgSubmit" className="h3 text-center hidden" />
                            <div className="clearfix" />
                        </div>
                        </div>
                    </form>
                    </div>
                </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default BuyFranchise